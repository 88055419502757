import type { HandleClientError } from '@sveltejs/kit';

import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import posthog from 'posthog-js';

import { browser, dev, version } from '$app/environment';
import {
  PUBLIC_SENTRY_DSN,
  PUBLIC_SENTRY_ORGANIZATION,
  PUBLIC_SENTRY_PROJECT_ID
} from '$env/static/public';

import { Logger } from '$lib/axiom';

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: [
    posthog.sentryIntegration({
      organization: PUBLIC_SENTRY_ORGANIZATION,
      projectId: Number(PUBLIC_SENTRY_PROJECT_ID)
    })
  ],

  environment: dev ? 'development' : 'production',
  release: version
});

const axiomLogger = new Logger({
  args: { dev, browser, version }
});

const axiomErrorLogger: HandleClientError = async ({ error, event, status, message }) => {
  console.error('An error occurred on the client side:', error, event);

  const url = new URL(event.url);
  axiomLogger.error(`${message}`, {
    exception: error,
    request: {
      host: url.hostname,
      path: url.pathname,
      status: status
    },
    source: 'hooks-client'
  });

  await axiomLogger.flush();

  return { message };
};

export const handleError: HandleClientError = handleErrorWithSentry(axiomErrorLogger);
